.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.TrackerLinks {
  border-bottom: 1px solid #ccc;

  padding-left: 0;
  cursor: pointer;
}

.TrackerLink {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  color: black;
}

.active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
  color: blue;
}

.TrackerClickable {
  cursor: pointer;
}
